<template>
  <div class="container" id="content">
    <div class="title">
      <div class="title-top">
        <div class="el-title-item">
          <el-form ref="form" label-width="80px">
            <el-form-item label="">
              <el-button type="success" size="small" @click="addOption">新增</el-button>
            </el-form-item>
            <el-form-item label="" style="margin-left: 2vw">
              <!-- <el-input v-model="searchName" placeholder="请输入标题"></el-input> -->
              <el-select
                v-model="searchName"
                placeholder="请选择任务状态"
                >
                <el-option
                    v-for="item in stateList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <!-- <el-button style="margin-left: 1vw;" type="success" size="small" @click="search">搜索</el-button> -->
      </div>
    </div>
    <el-table
        v-loading="loading"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 1vh;">
      <el-table-column width="100" :index="indexMethod" label="序号" type="index" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="rwmc" label="任务名称" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="rwdz" label="任务地址" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="rwxq" label="任务详情" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="zxr" label="执行人" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <span>{{getName1(scope.row.zxr)}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="zxrxm" label="执行人姓名" show-overflow-tooltip align="center"></el-table-column> -->
      <el-table-column prop="zxsj" label="执行时间" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="status" label="执行状态" show-overflow-tooltip align="center">
        <template slot-scope="scope">
            <span v-if="scope.row.status == 0">未完成</span>
            <span v-if="scope.row.status == 1">已完成</span>
        </template>
      </el-table-column>
      <el-table-column prop="zxqk" label="执行情况" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="zxwcsj" label="执行完成时间" show-overflow-tooltip align="center"></el-table-column>
      <!-- <el-table-column prop="fjs" label="图片" show-overflow-tooltip align="center">
          <template slot-scope="scope">
              <img class="imgs" :src="scope.row.fjs" alt="">
          </template>
      </el-table-column> -->
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope">
          <!-- <el-button
            type="primary"
            size="small"
            icon="el-icon-document"
            @click="details(scope.row)"
          ></el-button> -->
          <el-button
            type="primary"
            size="small"
            icon="el-icon-s-promotion"
            @click="receipt(scope.row)"
          ></el-button>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-edit"
            @click="edits(scope.row)"
          ></el-button>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-delete"
            @click="deletes(scope.row.id)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
    <!--    编辑-->
    <el-dialog title="" :visible.sync="messageBox" width="31vw" class="addAlameBox">
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ formTitle }}</span>
        <span style=" position: absolute; top: 50%; transform: translateY(-50%); right: 0px; font-size: 2.22vh;"></span>
      </div>
      <div class="addAlaForm">
        <el-form ref="addForm" :rules="rules" :model="addForm" label-width="10vw">
          <el-form-item label="任务名称" prop="rwmc">
            <el-input v-model="addForm.rwmc" placeholder="请输入任务名称"></el-input>
          </el-form-item>
          <el-form-item label="任务地址" prop="rwdz">
            <el-input v-model="addForm.rwdz" placeholder="请输入任务地址"></el-input>
          </el-form-item>
          <el-form-item label="任务详情" prop="rwxq">
            <el-input v-model="addForm.rwxq" placeholder="请输入任务详情"></el-input>
          </el-form-item>
          <el-form-item label="执行人" prop="zxr">
            <el-select
                v-model="addForm.zxr"
                placeholder="请选择执行人"
                >
                <el-option
                    v-for="item in nameList"
                    :key="item.id"
                    :label="item.realName"
                    :value="item.userName"
                ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="执行时间" prop="zxsj">
            <el-date-picker v-model="addForm.zxsj" type="date" value-format="yyyy-MM-dd" placeholder="选择执行时间"></el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" v-show="showFlag">
          <el-button type="info" @click="messageBox = false">取消</el-button>
          <el-button type="primary" @click="saveForm('addForm')">确定</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 回执 -->
    <el-dialog title="" :visible.sync="messageBox2" width="31vw" class="addAlameBox">
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ formTitle }}</span>
        <span style=" position: absolute; top: 50%; transform: translateY(-50%); right: 0px; font-size: 2.22vh;"></span>
      </div>
      <div class="addAlaForm">
        <el-form ref="addForm2" :rules="rules2" :model="addForm2" label-width="10vw">
          <el-form-item label="执行情况" prop="zxqk">
            <el-input v-model="addForm2.zxqk" placeholder="请输入执行情况"></el-input>
          </el-form-item>
          <el-form-item label="执行完成时间" prop="zxwcsj">
            <el-date-picker v-model="addForm2.zxwcsj" type="date" value-format="yyyy-MM-dd" placeholder="选择执行完成时间"></el-date-picker>
          </el-form-item>
          <el-form-item class="isUpload" label="图片" prop="fjs">
            <el-upload
              class="avatar-uploader"
              action="/imgUpdata"
              list-type="picture-card"
              :on-success="handleAvatarSuccess2"
              :before-upload="beforeAvatarUpload2"
              :on-remove="removeList"
              :file-list="cImgfileList"
              :headers="{token}"
            >
              <i class="el-icon-plus avatar-uploader-icon uploadImg"></i>
              <div>
                <img
                    v-for="item, i in cImgfileList" :key="i"
                    class="businessImg avatar"
                    :src="item.url" alt=""
                >
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" v-show="showFlag2">
          <el-button type="info" @click="messageBox2 = false">取消</el-button>
          <el-button type="primary" @click="saveForm2('addForm2')">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data() {
    return {
      tableData: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      ids: 0,
      searchName: '',
      time1: '',
      addForm: {},
      addForm2: {
        fjs: [],
      },
      rules: {
        rwmc: [
          {required: true, message: "任务名称不可为空", trigger: ["blur", "change"]},
        ],
        rwdz: [
          {required: true, message: "任务地址不可为空", trigger: ["blur", "change"]},
        ],
        rwxq: [
          {required: true, message: "任务详情不可为空", trigger: ["blur", "change"]},
        ],
        zxr: [
          {required: true, message: "执行人不可为空", trigger: ["blur", "change"]},
        ],
        zxsj: [
          {required: true, message: "执行时间不可为空", trigger: ["blur", "change"]},
        ],
      },
      rules2: {
        zxqk: [
          {required: true, message: "执行情况不可为空", trigger: ["blur", "change"]},
        ],
        zxwcsj: [
          {required: true, message: "执行完成时间不可为空", trigger: ["blur", "change"]},
        ],
        fjs: [
          {required: true, message: "回执图片不可为空", trigger: ["blur", "change"]},
        ],
      },
      formTitle: "",
      messageBox: false,
      showFlag: false,
      value: true,
      typeList: [],
      stateList: [
        { id: 0, name: '未完成' },
        { id: 1, name: '已完成' },
      ],
      nameList: [],
      showFlag2: false,
      messageBox2: false,
      cImgfileList: [],
      urlList: [],
      token: '',
    };
  },
  mounted() {
    this.token = JSON.parse(window.localStorage.getItem('token'));
    // this.getInformation();
    this.getUserList();
    this.getList();
  },
  methods: {
    indexMethod(index) {
      return index + this.size * (this.page - 1) + 1;
    },
    getName1(val){
        for(let a = 0; a < this.nameList.length; a++){
            if(this.nameList[a].id == val){
                return this.nameList[a].realName;
            }
        }
    },
    //请求列表数据
    getList() {
      this.$get("/zfxx/page", {
        page: this.page,
        size: this.size,
        status: this.searchName,
      }).then((res) => {
        if (res.data.state == "success") {
          this.tableData = res.data.data;
          this.total = res.data.count;
          //console.log("获取列表", this.tableData)
        }
      });
    },
    // 获取村民信息
    getInformation() {
      this.$get("/villagerInformation/getVillagerInformations", {
        page: 1,
        size: 100,
      }).then((res) => {
        if (res.data.state == "success") {
          this.typeList = res.data.datas;
        }
      });
    },
    // 获取用户列表
    getUserList() {
      this.$get("/userManage/getUserList", {
        page: 1,
        limit: 100,
      }).then((res) => {
        if (res.data.state == "success") {
          this.nameList = res.data.datas;
        }
      });
    },
    // 回执
    receipt(row){
      //console.log(row);
      this.$get(`/zfxx/detail/${row.id}`).then((res) => {
        if(res.data.state == 'success'){
          this.cImgfileList = [];
          for(let a = 0; a < res.data.data.fjs.length; a++){
            // this.cImgfileList.push({name: res.data.data.fjs[a], url: res.data.data.fjs[a]});
            this.$getBlob("/fileops/show", {
              fileName: res.data.data.fjs[a],
            }).then((res2) => {
              //console.log(res2);
              let fileNames = res2.config.params.fileName;
              let fileData = fileNames.split(".")[1];
              let blob = new Blob([res2.data],{type: 'image/'+ fileData})
              let url = window.URL.createObjectURL(blob);
              this.cImgfileList.push({name: res.data.data.fjs[a], url: url});
            })
          }
          //console.log(this.cImgfileList);

        }
      })
      this.showFlag2 = true;
      this.ids = row.id;
      this.formTitle = "回执";
      this.messageBox2 = true;
      this.$nextTick(() => {
        this.addForm2 = {};
        this.$refs.addForm2.resetFields();
        this.addForm2 = JSON.parse(JSON.stringify(row));
      });
    },
    // 编辑
    edits(row) {
      this.showFlag = true;
      this.ids = row.id;
      this.formTitle = "编辑";
      this.messageBox = true;
      this.$nextTick(() => {
        this.addForm = {};
        this.$refs.addForm.resetFields();
        this.addForm = JSON.parse(JSON.stringify(row));
      });
    },
    // 删除
    deletes(id) {
        // 弹出提示是否删除
        this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(() => {
                this.$delete(`/zfxx/delete/${id}`).then((res) => {
                    if (res.data.state == "success") {
                        this.$message.success("删除成功");
                        this.getList();
                    } else {
                        this.$message.error("删除失败");
                    }
                });
            })
            .catch((e) => e);
    },
    // 确认提交
    saveForm(formName) {
        this.addForm.id = this.ids;
        let url = '/zfxx/save';
        if(this.formTitle == '新增'){
            delete this.addForm.id;
        }
        this.$refs[formName].validate((valid) => {
            if (valid) {
                this.$postJSON(url, this.addForm
                ).then((res) => {
                    if (res.data.state == 'success') {
                        if(this.formTitle == '新增'){
                            this.$message.success('添加成功')
                        }else{
                            this.$message.success('修改成功')
                        }
                        this.messageBox = false
                        this.getList();
                    } else {
                        this.$message.error(res.data.msg)
                        this.messageBox = false
                    }
                })
            } else {
                // //console.log('error submit!!');
                return false;
            }
        });
    },
    // 回执保存
    saveForm2(formName) {
        this.addForm2.id = this.ids;
        
        let url = '/zfxx/saveReceipt';
        this.$refs[formName].validate((valid) => {
            if (valid) {
                this.$postJSON(url, this.addForm2
                ).then((res) => {
                    if (res.data.state == 'success') {
                        this.$message.success('保存成功')
                        this.messageBox2 = false
                        this.getList();
                    } else {
                        this.$message.error(res.data.msg)
                        this.messageBox2 = false
                    }
                })
            } else {
                // //console.log('error submit!!');
                return false;
            }
        });
    },
    // 新增
    addOption() {
      this.formTitle = "新增";
      this.messageBox = true;
      this.showFlag = true;
      this.$nextTick(() => {
        this.addForm = {};
        this.$refs.addForm.resetFields();
      });
    },
    beforeAvatarUpload2(val) {
      const fileLimitSize = 5;// 几兆限制
      const isJPG = val.type === "image/jpeg";
      const isJPGs = val.type === "image/png";
      const fileLimit = val.size / 1024 / 1024 < fileLimitSize;
      if (!isJPG && !isJPGs) {
        this.$message.info("上传图片格式只能为 JPG 或 PNG 格式！");
        this.fileList2 = [];
        return false
      }
      if (!fileLimit) {
        this.$message.info(`上传图片大小不能超过${fileLimitSize}MB!`);
        this.fileList2 = [];
        return false
      }

      return true;
    },
    handleAvatarSuccess2(file) {
      // //console.log(file)
      // let urlAll = [];
      // urlAll.push(file.data);
      // urlAll.forEach(item => {
      //   this.cImgfileList.push({name: "http://cloudback.half-half.cn/file/" + item, url: "http://cloudback.half-half.cn/file/" + item})
      // })
      // this.addForm2.fjs = this.cImgfileList;
      // this.addForm2 = JSON.parse(JSON.stringify(this.addForm2));
      // //console.log(this.addForm2.fjs);

      this.$getBlob("/fileops/show", {
        fileName: file.data,
      }).then((res) => {
        let fileNames = res.config.params.fileName;
        let fileData = fileNames.split(".")[1];
        //console.log(fileData);
        let blob = new Blob([res.data],{type: 'image/'+ fileData})
        let url = window.URL.createObjectURL(blob);
        this.cImgfileList.push({name: file.data, url: url});
        this.urlList.push(file.data);
        this.addForm2.fjs = this.urlList;
        //console.log(this.addForm2.fjs);
      })
    },
    removeList(file, fileList){
      //console.log(file, fileList);
      this.cImgfileList = fileList;
      let list = [];
      for(let a = 0; a < this.cImgfileList.length; a++){
        list.push(this.cImgfileList[a].name);
      }
      this.addForm2.fjs = list;
    },
    search() {
      this.page = 1
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
#content /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
}

#content /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;
}

#content /deep/ .el-table::-webkit-scrollbar {
  display: none;
}

.addAlaForm /deep/ .el-select {
  width: 100% !important;
}

.dialog-footer {
  height: 5vh;
  text-align: right;
}

.dialog-footer /deep/ .el-button, .el-button--info {
  margin-left: 10px !important;

}

.dialog-footer /deep/ .el-button--info {
  background-color: transparent !important;
  color: #000;
}

.title /deep/ .el-input__inner {
  height: 33px !important;
}
.isUpload /deep/ .el-upload--picture-card{
  line-height: 12vh;
}

.drawerBox /deep/ .msg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vh 1vw;
}
.isUpload /deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 12vh;
  height: 12vh;
}
.isUpload /deep/.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.isUpload /deep/.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 12vh;
  height: 12vh;
  line-height: 12vh;
  text-align: center;
}
.isUpload /deep/.el-upload-list__item{
  width: 12vh;
  height: 12vh;
}
.isUpload .businessImg {
  width: 12vh;
  height: 12vh;
}
.imgs{
    width: 6vh;
    height: 6vh;
}

</style>


